import React from 'react';
import './backdrop.scss';

interface BackdropProps {
    onClick: () => void;
}

const Backdrop: React.FC<BackdropProps> = ({ onClick }) => {
    return (
        <div className="backdrop" onClick={onClick} />
    );
};

export default Backdrop;