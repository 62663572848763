import React from 'react';
import classNames from 'classnames';
import './drawer.scss';
import DrawerCloseButton from './DrawerCloseButton';
import logo from '../../../src/assets/images/dit-is-nederland-logo.svg';

interface DrawerProps {
    isOpen: boolean;
    onCloseHandler: () => void;
}

const Drawer: React.FC<DrawerProps> = ({ isOpen, onCloseHandler }) => {
    let drawerClassNames = classNames('drawer', { 'open': isOpen });

    return (
        <div className={drawerClassNames}>
            <div className='drawer__header'>
                <DrawerCloseButton onClick={onCloseHandler} />
            </div>
            <div className='drawer__container'>
                <div>
                    <div className='drawer__title'>Ga naar</div>
                    <div className='drawer__navigation-items'>
                        <ul>
                            <li>
                                <a href="https://dit.eo.nl">Laatste artikelen</a>
                            </li>
                            <li>
                                <a href="https://dit.eo.nl/over-dit-is-nederland">Over Dit is Nederland</a>
                            </li>
                            <li>
                                <a href="https://dit.eo.nl/hoe-kun-je-meedoen-met-dit-is-nederland">Hoe werkt deze pagina?</a>
                            </li>
                            <li>
                                <a href="https://dit.eo.nl/nieuwsbrief">Nieuwsbrief</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <div className='drawer__title'>Volg ons op</div>
                    <div className='drawer__navigation-items'>
                        <ul>
                            <li>
                                <a href="https://api.whatsapp.com/send/?phone=31645923535&text=DIT%20AAN">WhatsApp</a>
                            </li>
                            <li>
                                <a href="https://www.facebook.com/dit.eo">Facebook</a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/dit.eo/">Instagram</a>
                            </li>
                            <li>
                                <a href="https://twitter.com/dit_eo">X</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <div className='drawer__navigation-items drawer__navigation-items--small'>
                        <ul>
                            <li>
                                <a href="https://www.eo.nl/algemene-voorwaarden">Algemene voorwaarden</a>
                            </li>
                            <li>
                                <a href="https://www.eo.nl/privacy">Privacy</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div>
                <img src={logo} className="App-logo" alt="logo" />
            </div>
        </div>
    );
};

export default Drawer;