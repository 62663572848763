import React from 'react';
import Layout from '../../components/Layout';
import WhatsappFollowBox from '../../components/WhatsappFollowBox';
import { Box, Flex, VStack, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const Main: React.FC = () => {
    const askOnWhatsApp = "https://api.whatsapp.com/send/?phone=31645923535&text=Dit%20is%20mijn%20vraag%20-%20";
    const navigate = useNavigate();

    let handleVote = () => {
        navigate('/vote', { state: { prevUrl: '/' } });
    }

    let handleAskQuestion = () => {
        window.open(askOnWhatsApp, '_blank');
    }

    return (
        <Layout>
            <VStack rowGap={6}>
                <Flex
                    flexDirection={"column"}
                    textAlign="left"
                    padding="16px"
                    border="solid 1px #BFBFBF"
                    borderRadius="40px"
                    width="100%"
                    marginBottom="16px"
                >
                    <Box
                        fontSize={"32px"}
                        lineHeight={"32px"}
                        margin={"0 0 24px 0"}
                        fontWeight={"700"}
                        color={"#000"}
                    >
                        Wil je stemmen of een vraag stellen?
                    </Box>
                    <Button variant={"outline"} onClick={() => handleVote()} justifyContent={"left"} marginBottom={4} height={"44px"}>
                        Ik wil stemmen
                    </Button>
                    <Button variant={"outline"} onClick={() => handleAskQuestion()} justifyContent={"left"} marginBottom={4} maxHeight={"44px"}>
                        Ik wil een vraag stellen
                    </Button>
                </Flex>
                <WhatsappFollowBox />
            </VStack>
        </Layout>
    );
};

export default Main;