import React from 'react';
import Layout from '../../components/Layout';
import { useNavigate } from 'react-router-dom';
import { useVotingContext } from '../../components/VotingProvider';
import {
    Button,
    Box,
    Flex,
    VStack,
    FormControl,
    FormLabel,
    Input,
    useDisclosure,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    FormErrorMessage
} from '@chakra-ui/react';
import { ReactComponent as IconArrowRightWhite } from '../../assets/images/icon-arrow-right-white.svg';
import { ReactComponent as IconArrowRightOrange } from '../../assets/images/icon-arrow-right-orange.svg';
import BackButton from '../../components/Button/BackButton';
import { useMutation } from '@tanstack/react-query';
import { pollsQueries } from '../../api/poll/poll';
import { CompleteAnswer } from '../../models/poll/question.types';
import axios from 'axios';

const Thanks: React.FC = () => {
    const navigate = useNavigate();
    const { uuid, answer, setAnswer } = useVotingContext();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef<HTMLButtonElement | null>(null);

    let [email, setEmail] = React.useState(answer.email);
    let [isEmailValid, setIsEmailValid] = React.useState(true);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);

    let handleSubmit = () => {
        if (email && !validateEmail(email)) {
            setIsEmailValid(false);
            return;
        }
        setIsEmailValid(true);
        if (!email) {
            onOpen();
            return;
        }
        const updatedAnswer = { ...answer, email: email, phone: "" };
        setAnswer(updatedAnswer);
        updateVote({ uuidParam: uuid, answerParam: updatedAnswer });
    }

    let handleSkip = () => {
        updateVote({ uuidParam: uuid, answerParam: answer });
    }

    const { mutate: updateVote } = useMutation({
        mutationKey: ['vote'],
        mutationFn: ({ uuidParam, answerParam }: { uuidParam: string, answerParam: CompleteAnswer }) => pollsQueries.putAnswer(uuidParam, answerParam),
        onSuccess: () => {
            navigate('/share', { state: { prevUrl: '/thanks' } });
        },
        onError(error) {
            if (axios.isAxiosError(error)) {
                const serverResponse = error.response;
                if (serverResponse && serverResponse.status === 429) {
                    navigate('/too-many-requests', { state: { prevUrl: '/thanks' } });
                }
            }
        },
    });

    return (
        <Layout>
            <Box marginTop={-2} paddingBottom={4}>
                <BackButton />
            </Box>
            <Flex direction={"column"} rowGap={8}>
                <Box className="container" paddingTop={6} paddingBottom={8}>
                    <Box
                        fontSize={"32px"}
                        lineHeight={"32px"}
                        margin={"0 0 24px 0"}
                        fontWeight={"700"}
                        color={"#000"}
                    >
                        Bedankt voor je stem en je reactie! 👏🏼
                    </Box>
                    <Box
                        fontSize={"14px"}
                        marginBottom={4}
                    >
                        Mogen we naar aanleiding van je reactie contact met je opnemen?
                    </Box>
                    <VStack spacing={4}>
                        <FormControl isInvalid={!isEmailValid}>
                            <FormLabel>Wat is je e-mailadres?</FormLabel>
                            <Input type="email" onChange={handleEmailChange} value={email} />
                            {!isEmailValid && <FormErrorMessage>Email is not valid.</FormErrorMessage>}
                        </FormControl>
                    </VStack>
                </Box>
                <Flex direction={"column"} rowGap={4}>
                    <Button variant={"solid"} onClick={handleSubmit} justifyContent={"center"} fontWeight={600} rightIcon={<IconArrowRightWhite />}>
                        Ja, leuk! 👍
                    </Button>
                    <Button variant={"outline"} onClick={handleSkip} justifyContent={"center"} fontWeight={600} rightIcon={<IconArrowRightOrange />}>
                        Nee, liever niet
                    </Button>
                </Flex>
            </Flex>
            <AlertDialog
                motionPreset='slideInBottom'
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isCentered
            >
                <AlertDialogOverlay>
                    <AlertDialogContent width="80%">
                        <AlertDialogHeader fontSize='lg' fontWeight='bold' textAlign={"center"}>
                            Gegevens ontbreken
                        </AlertDialogHeader>

                        <AlertDialogBody textAlign={"center"}>
                            Het lijkt erop dat je nog geen gegevens hebt ingevuld.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button variant={"solid"} ref={cancelRef} onClick={onClose} justifyContent={"center"}>
                                Oke
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Layout>
    );
};

export default Thanks;

const validateEmail = (email: String) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};