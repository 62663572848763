import { defineStyleConfig } from '@chakra-ui/react';

const Textarea = defineStyleConfig({
    baseStyle: {
        borderRadius: '15px',
        bg: "red",
    },
    variants: {
        outline: {
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "brand.basicGrey25p",
        },
    },
});
  
const Button = defineStyleConfig({
    baseStyle: {
      background: "brand.basicWhite",
      color: "brand.primary",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: "brand.primary",
      borderRadius: 40,
      width: "100%",
      fontWeight: 500,
      justifyContent: "flex-start",
      textIndent: 8,
      
    },
    sizes: {
      md: {
        height: "48px",
        fontSize: 14,
      },
    },
    variants: {
      solid: {
        bg: "brand.primary",
        color: "brand.basicWhite",
        _hover: {
          bg: "brand.basicWhite",
          color: "brand.primary",
        },
        _active: {
          bg: "brand.basicWhite",
          color: "brand.primary",
        }
      },
      outline: {
        bg: "brand.basicWhite",
        color: "brand.primary",
        _hover: {
          bg: "brand.primary",
          color: "brand.basicWhite",
        },
        _active: {
          bg: "brand.primary",
          color: "brand.basicWhite",
        }
      }
    },
    
    defaultProps: {
      size: 'md',
      variant: 'outline',
    },
});

const FormControl = defineStyleConfig({
  baseStyle: {
    marginBottom: 16,
  },
});

const FormLabel = defineStyleConfig({
  baseStyle: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "16.8px",
    color: "brand.basicBlack",
    marginBottom: 2,
  },
});

const Input = defineStyleConfig({
  variants: {
    outline: {
      field: {
        fontWeight: 500,
        fontSize: 16,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "brand.basicGrey25p",
        color: "brand.basicBlack",
        borderRadius: 15,
      },
    },
  },
});

const ditTheme = {
    colors: {
        brand: {
            primary: "#FF6B00",
            secondary: "#FFECCB",
            background: "#C84800",
            basicBlack: "#000",
            basicGrey75p: "#404040",
            basicGrey50p: "#7F7F7F",
            basicGrey25p: "#BFBFBF",
            basicWhite: "#FFF",
        },
    },
    components: {
        Button,
        Textarea,
        FormControl,
        FormLabel,
        Input,
    },
};

export default ditTheme;