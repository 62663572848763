import React, { ReactNode, useEffect, useState } from 'react';
import Header from '../Header';
import Drawer from '../Header/Drawer';
import Backdrop from '../Header/Backdrop';
import Footer from '../Footer';
import './layout.scss';
import { Box } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

interface LayoutProps {
    children: ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const location = useLocation();

    const drawerToggleClickHandler = () => {
        setDrawerOpen(_drawerOpen => !_drawerOpen);
    };

    const backdropClickHandler = () => {
        setDrawerOpen(false);
    };

    const drawerCloseButtonClickHandler = () => {
        setDrawerOpen(false);
    };

    useEffect(() => {
      // @ts-ignore
      trackPianoPageView(location.pathname, location.state?.prevUrl || '');
    }, [location]);

    return (
        <div className="layout">
          <Header drawerClickHandler={drawerToggleClickHandler} />
          <Drawer isOpen={drawerOpen} onCloseHandler={drawerCloseButtonClickHandler} />
          {
            drawerOpen &&
              <>
                <Backdrop onClick={backdropClickHandler} />
              </>
          }
          <main>
            <Box maxW={["100%", "100%", "960px"]} mx="auto">
              {children}
            </Box>
          </main>
          <Footer />
        </div>
    );
};

export default Layout;