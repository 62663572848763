import React from 'react';
import './tooManyRequests.scss';
import Layout from '../../components/Layout';
import iconWhatsapp from '../../assets/images/icon-whatsapp.svg';

const TooManyRequests: React.FC = () => {

    return (
        <Layout>
            <div className="container container-too-many-requests">
                <div className="container-too-many-requests__title">Teveel verzoeken</div>
                <div className="container-too-many-requests__description">We zien dat je meerdere keren op de stelling probeert te stemmen. Je kunt per show maximaal 5 reacties achter laten.</div>
            </div>
            <div className="container container-too-many-requests">
                <div className="container-too-many-requests__title-get-updates">Als eerste op de hoogte?</div>
                <div className="container-too-many-requests__description-get-updates">Vaker meedoen? Volg DIT gratis via WhatsApp (dagelijks max 1 bericht)</div>
                <div className="container-too-many-requests__whatsapp-get-updates">
                    <a href="https://wa.me/31645923535" target="_blank" rel="noreferrer">
                        <img src={iconWhatsapp} alt="whatsapp" className="whatsapp" />
                        <span>Volg DIT op WhatsApp</span>
                    </a>
                </div>
            </div>
        </Layout>
    );

};

export default TooManyRequests;