import React from 'react';
import { Spinner as SpinnerBase, Flex } from '@chakra-ui/react';

const Spinner: React.FC = () => {
    return (
        <Flex
            justifyContent="center"
            alignItems="center"
            minH="calc(100vh - 301px)" //100vh - header - footer
        >
            <SpinnerBase
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='#FF6B00'
                size='xl'
            />
        </Flex>
    );
};

export default Spinner;
