import React from 'react';
import Layout from '../../components/Layout';
import { useNavigate } from 'react-router-dom';
import { useVotingContext } from '../../components/VotingProvider';
import {
    Button,
    Box,
    Flex,
    VStack,
    FormControl,
    FormLabel,
    Input,
    useDisclosure,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    Link,
    Text,
} from '@chakra-ui/react';
import { ReactComponent as IconArrowRightWhite } from '../../assets/images/icon-arrow-right-white.svg';
import { ReactComponent as IconArrowRightOrange } from '../../assets/images/icon-arrow-right-orange.svg';
import BackButton from '../../components/Button/BackButton';

const Information: React.FC = () => {
    const navigate = useNavigate();
    const { answer, setAnswer } = useVotingContext();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef<HTMLButtonElement | null>(null);
    const termsAndConditionsUrl = 'https://dit.eo.nl/algemene-voorwaarden';

    let [name, setName] = React.useState(answer.name);
    let [city, setCity] = React.useState(answer.city);

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);
    const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => setCity(e.target.value);

    let handleSubmit = () => {
        if (!name || !city) {
            onOpen();
            return;
        }
        setAnswer({ ...answer, name: name, city: city, displayOnTv: true });
        navigate('/thanks', { state: { prevUrl: '/information' }});
    }
    
    let handleSkip = () => {
        setAnswer({ ...answer, displayOnTv: true });
        navigate('/thanks', { state: { prevUrl: '/information' }});
    }

    return (
        <Layout>
            <Box marginTop={-2} paddingBottom={4}>
                <BackButton />
            </Box>
            <Flex direction={"column"} rowGap={8}>
                <Box className="container" paddingTop={6} paddingBottom={12}>
                    <Box
                        fontSize={"32px"}
                        lineHeight={"32px"}
                        margin={"0 0 24px 0"}
                        fontWeight={"700"}
                        color={"#000"}
                    >
                        We willen je graag iets beter leren kennen 👋🏼
                    </Box>
                    <VStack spacing={4}>
                        <FormControl>
                            <FormLabel>Wat is je voornaam?</FormLabel>
                            <Input onChange={handleNameChange} value={name} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Wat is je woonplaats?</FormLabel>
                            <Input onChange={handleCityChange} value={city} />
                        </FormControl>
                    </VStack>
                </Box>
                <Flex direction={"column"} rowGap={4}>
                    <Button variant={"solid"} onClick={handleSubmit} justifyContent={"center"} fontWeight={600} rightIcon={<IconArrowRightWhite/>}>
                        Insturen
                    </Button>
                    <Button variant={"outline"} onClick={handleSkip} justifyContent={"center"} fontWeight={600} rightIcon={<IconArrowRightOrange/>}>
                        Overslaan
                    </Button>
                </Flex>
                <Box>
                    <Text fontSize={14}>
                        Door op insturen te klikken ga je akkoord met de{' '}
                        <Link textDecoration={'underline'} href={termsAndConditionsUrl} target="_blank">
                            algemene voorwaarden
                        </Link>
                    </Text>
                </Box>
            </Flex>
            <AlertDialog
                motionPreset='slideInBottom'
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isCentered
            >
                <AlertDialogOverlay>
                    <AlertDialogContent width="80%">
                        <AlertDialogHeader fontSize='lg' fontWeight='bold' textAlign={"center"}>
                            Gegevens ontbreken
                        </AlertDialogHeader>

                        <AlertDialogBody textAlign={"center"}>
                            Het lijkt erop dat je nog geen gegevens hebt ingevuld. 
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button variant={"solid"} ref={cancelRef} onClick={onClose} justifyContent={"center"}>
                                Oke
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Layout>
    );
};

export default Information;