import React from 'react';
import './App.scss';
import VotingClosed from './pages/VotingClosed';
import Vote from './pages/Vote';
import Main from './pages/Main';
import Comment from './pages/Comment';
import TooManyRequests from './pages/TooManyRequests';
import Information from './pages/Information';
import Thanks from './pages/Thanks';
import Share from './pages/Share';
import {
  Route,
  BrowserRouter as Router,
  Routes
} from 'react-router-dom';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { VotingProvider } from './components/VotingProvider';

import { ChakraProvider } from '@chakra-ui/react'
import { extendTheme } from '@chakra-ui/react';
import ditTheme from './styles/theme';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'

const theme = extendTheme(ditTheme);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      refetchOnWindowFocus: "always"
    }
  }
})

function App() {
  const gtmParams = {
    id: 'GTM-KKB5RFJ',
    customDomain: "https://load.sst.eo.nl",
    customScriptName: "ljpkmfzp.js"
  }

  return (
    <GTMProvider state={gtmParams}>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <VotingProvider>
            <Router>
              <Routes>
                <Route path="/" element={
                  <Main />
                } />
                <Route path="/vote" element={
                  <VotingClosed>
                    <Vote />
                  </VotingClosed>
                } />
                <Route path="/comment" element={
                  <VotingClosed>
                    <Comment />
                  </VotingClosed>
                } />
                <Route path="/information" element={
                  <VotingClosed>
                    <Information />
                  </VotingClosed>
                } />
                <Route path="/thanks" element={
                  <VotingClosed>
                    <Thanks />
                  </VotingClosed>
                } />
                <Route path="/share" element={
                  <VotingClosed>
                    <Share />
                  </VotingClosed>
                } />
                <Route path="/too-many-requests" element={
                  <TooManyRequests />
                } />
              </Routes>
            </Router>
          </VotingProvider>
        </ChakraProvider>
        <ReactQueryDevtools buttonPosition={"bottom-left"} />
      </QueryClientProvider>
    </GTMProvider>
  );
}

export default App;
