import React from 'react';
import './drawerToggleButton.scss';
import iconHamburger from '../../../src/assets/images/icon-hamburger.svg';

interface DrawerToggleButtonProps {
    onClick: () => void;
}

const DrawerToggleButton: React.FC<DrawerToggleButtonProps> = ({ onClick }) => {
    return (
        <button className="drawerToggleButton" onClick={onClick}>
            <img src={iconHamburger} alt="menu" />
        </button>
    );
};

export default DrawerToggleButton;