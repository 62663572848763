import React from 'react';
import './drawerCloseButton.scss';
import iconClose from '../../../src/assets/images/icon-close.svg';

interface DrawerCloseButtonProps {
    onClick: () => void;
}

const DrawerCloseButton: React.FC<DrawerCloseButtonProps> = ({ onClick }) => {
    return (
        <button className="drawerCloseButton" onClick={onClick}>
            <img src={iconClose} alt="menu" />
        </button>
    );
};

export default DrawerCloseButton;