import React from 'react';
import iconWhatsapp from '../../assets/images/icon-whatsapp.svg';
import { Box, Flex, Link, VStack } from '@chakra-ui/react';

interface WhatsappFollowBoxProps {
    titleFontSize?: number;
    contentFontSize?: number;
}

const WhatsappFollowBox: React.FC<WhatsappFollowBoxProps> = ({ titleFontSize, contentFontSize }) => {
    const followOnWhatsApp = "https://api.whatsapp.com/send/?phone=31645923535&text=DIT%20AAN";

    return (
        <Flex
            flexDirection={"column"}
            textAlign="center"
            padding="16px"
            border="solid 1px #BFBFBF"
            borderRadius="40px"
            width="100%"
        >
            <Box
                fontSize={titleFontSize || "24px"}
                lineHeight={titleFontSize ? 1 : "26px"}
                fontWeight="700"
            >
                Vaker meedoen?
            </Box>
            <VStack
                rowGap={6}
                paddingTop={contentFontSize ? "24px" : "12px"}
            >
                <Box
                    fontSize={contentFontSize || "14px"}
                    paddingLeft="50px"
                    paddingRight="50px"
                >
                    Je krijgt als eerste de stelling én kunt vragen stellen! (dagelijks max. 1 bericht)
                </Box>
                <Link
                    color="#FF6B00"
                    fontWeight="600"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    columnGap="16px"
                    textDecoration="none"
                    href={followOnWhatsApp}
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={iconWhatsapp} alt="whatsapp" className="whatsapp" />
                    <span>Volg DIT op WhatsApp</span>
                </Link>
            </VStack>
        </Flex>
    );
};

export default WhatsappFollowBox;
