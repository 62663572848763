import React from 'react';
import './votingClosed.scss';
import Layout from '../../components/Layout';
import Spinner from '../../components/Spinner';
import { useQuery } from "@tanstack/react-query";
import { pollsQueries } from '../../api/poll/poll';
import WhatsappFollowBox from '../../components/WhatsappFollowBox';


interface VotingClosedProps {
    children: JSX.Element;
}

const VotingClosed: React.FC<VotingClosedProps> = ({ children }) => {
    const { isLoading, data: questions } = useQuery({
        queryKey: ['questions'],
        queryFn: pollsQueries.getPollsQuestion,
        refetchOnWindowFocus: "always",
        refetchOnReconnect: "always"
    });

    const areQuestionsAvailable: boolean = (questions?.length ?? 0) > 0 || false;

    if (isLoading) {
        return (
            <Layout>
                <Spinner />
            </Layout>
        );
    }
    if (!areQuestionsAvailable) {
        return (
            <Layout>
                <div className="container container-voting-closed">
                    <div className="container-voting-closed__title">Je kunt nu niet stemmen</div>
                    <div className="container-voting-closed__description">Er is geen actieve stelling waar je aan mee kunt doen. Op maandag en donderdag kun je iedere middag hier een nieuwe stelling vinden.</div>
                </div>
                <WhatsappFollowBox />
            </Layout>
        );
    }

    return children;
};

export default VotingClosed;