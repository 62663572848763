import React from 'react';
import './comment.scss';
import Layout from '../../components/Layout';
import { useNavigate } from 'react-router-dom';
import { useVotingContext } from '../../components/VotingProvider';
import {
    Textarea,
    Button,
    Box,
    Flex,
    useDisclosure,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
} from '@chakra-ui/react';
import { ReactComponent as IconArrowRightWhite } from '../../assets/images/icon-arrow-right-white.svg';
import { ReactComponent as IconArrowRightOrange } from '../../assets/images/icon-arrow-right-orange.svg';
import { pollsQueries } from '../../api/poll/poll';
import { useQuery } from "@tanstack/react-query";
import { Question } from '@/models/poll/question.types';

const Comment: React.FC = () => {
    const navigate = useNavigate();
    const { answer, setAnswer } = useVotingContext();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef<HTMLButtonElement | null>(null);

    const { data: questions } = useQuery({
        queryKey: ['questions'],
        queryFn: pollsQueries.getPollsQuestion,
        staleTime: Infinity
    });

    const question: Question | undefined = questions?.[0];
    let choice_text = question?.choices.find(choice => Number(choice.id) === answer.choice)?.choice;

    let [comment, setComment] = React.useState(answer.comment || '')
    let [charCount, setCharCount] = React.useState(0)

    let handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let inputValue: string = e.target.value;
        if (inputValue.length > 100) {
            return;
        }
        setCharCount(inputValue.length);
        setComment(inputValue);
    }

    let handleSubmit = () => {
        if (comment.length === 0) {
            onOpen();
            return;
        }
        setAnswer({ ...answer, comment: comment });
        navigate('/information', { state: { prevUrl: '/comment' } });
    }

    let handleSkip = () => {
        navigate('/information', { state: { prevUrl: '/comment' } });
    }

    return (
        <Layout>
            <Flex direction={"column"} rowGap={8}>
                <Box className="container" paddingBottom={4}>
                    <div className="question">• {question?.question}</div>
                    <div className="subject">Waarom heb je  ‘{choice_text}’ gekozen?</div>
                    <Textarea
                        size="sm"
                        resize="vertical"
                        minHeight={245}
                        value={comment}
                        onChange={handleInputChange} />
                    <Flex
                        justifyContent={"flex-end"}
                        fontSize={14}
                        marginTop={4}
                        color={"brand.basicGrey50p"}
                    >{charCount}/100 tekens</Flex>
                </Box>
                <Flex direction={"column"} rowGap={4}>
                    <Button variant={"solid"} onClick={handleSubmit} justifyContent={"center"} fontWeight={600} rightIcon={<IconArrowRightWhite />}>
                        Insturen
                    </Button>
                    <Button variant={"outline"} onClick={handleSkip} justifyContent={"center"} fontWeight={600} rightIcon={<IconArrowRightOrange />}>
                        Overslaan
                    </Button>
                </Flex>
            </Flex>
            <AlertDialog
                motionPreset='slideInBottom'
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isCentered

            >
                <AlertDialogOverlay>
                    <AlertDialogContent width="80%">
                        <AlertDialogHeader fontSize='lg' fontWeight='bold' textAlign={"center"}>
                            Reactie ontbreekt
                        </AlertDialogHeader>

                        <AlertDialogBody textAlign={"center"}>
                            Het lijkt erop dat je nog geen reactie hebt toegevoegd.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button variant={"solid"} ref={cancelRef} onClick={onClose} justifyContent={"center"}>
                                Oke
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Layout>
    );
};

export default Comment;