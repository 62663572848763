import { CompleteAnswer } from '@/models/poll/question.types';
import React, { createContext, useContext, useState } from 'react';

interface VotingContextType {
    uuid: string;
    setUuid: (data: string) => void;
    answer: CompleteAnswer;
    setAnswer: (data: CompleteAnswer) => void;
}

export const VotingContext = createContext<VotingContextType>({} as VotingContextType);

interface VotingContextProps {
    children: React.ReactNode;
}

export const VotingProvider = ({ children }: VotingContextProps) => {
    const [ answer, setAnswer] = useState<CompleteAnswer>({} as CompleteAnswer);
    const [ uuid, setUuid] = useState<string>("");

    return (
        <VotingContext.Provider value={{ answer, setAnswer, uuid, setUuid }}>
            {children}
        </VotingContext.Provider>
    );
};

export const useVotingContext = () => {
    const context = useContext(VotingContext);
    if (!context) {
        throw new Error('useVotingContext must be used within a VotingProvider');
    }
    return context;
}