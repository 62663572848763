import { instance } from "../";

import { Questions, Answer, AnswerResponse, CompleteAnswer } from "@/models/poll/question.types";

export const pollsQueries = {
  async getPollsQuestion(): Promise<Questions> {
    const { data } = await instance.get("polls/question/");
    return data;
  },
  async voteQuestion(answer: Answer): Promise<AnswerResponse> {
    const { data } = await instance.post('/polls/answer/', answer);
    return data;
  },
  async putAnswer(uuid: string, answer: CompleteAnswer): Promise<CompleteAnswer> {
    // Transform the input to match the API spec
    let answerData: any = { ...answer, display_on_tv: answer.displayOnTv };
    delete answerData.displayOnTv;

    const { data } = await instance.put(`/polls/answer/${uuid}/`, answerData);
    return data;
  }
};
