import React from 'react';
import './header.scss';
import logo from '../../../src/assets/images/dit-is-nederland-logo.svg';
import DrawerToggleButton from './DrawerToggleButton';
import { useNavigate } from 'react-router-dom';

interface HeaderProps {
    drawerClickHandler: () => void;
}

const Header: React.FC<HeaderProps> = ({ drawerClickHandler }) => {
    const navigate = useNavigate();

    const handleLogoClick = () => {
        navigate('/');
    }

    return (
        <header className='menu'>
            <nav className='menu__navigation'>
                <div className='menu__logo'>
                    <img src={logo} className="App-logo" alt="logo" onClick={handleLogoClick} />
                </div>
                <DrawerToggleButton onClick={drawerClickHandler}/>
            </nav>
        </header>
    );
};

export default Header;