import React from 'react';
import './footer.scss';
import logo from '../../../src/assets/images/eo-logo.svg';
import { Image } from '@chakra-ui/react';

const Footer: React.FC = () => {
    return (
        <footer className='layout-footer'>
            <div>
                <Image src={logo} alt="EO logo" height="40px" />
            </div>
            <div>Iedere maandag en donderdag 16.10 uur bij de EO op NPO2</div>
        </footer>
    );
};

export default Footer;