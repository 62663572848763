import React from 'react';
import Layout from '../../components/Layout';
import WhatsappFollowBox from '../../components/WhatsappFollowBox';

const Share: React.FC = () => {
    return (
        <Layout>
            <WhatsappFollowBox titleFontSize={32} contentFontSize={16} />
        </Layout>
    );
};

export default Share;