import React from 'react';
import './vote.scss';
import Button from '../../components/Button';
import Layout from '../../components/Layout';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from "@tanstack/react-query";
import { pollsQueries } from '../../api/poll/poll';
import { Question, Choice, Answer } from '@/models/poll/question.types';
import { useVotingContext } from '../../components/VotingProvider';
import axios from 'axios';

const Vote: React.FC = () => {
  const navigate = useNavigate();
  const { answer, setUuid, setAnswer } = useVotingContext();

  const { data: questions } = useQuery({
    queryKey: ['questions'],
    queryFn: pollsQueries.getPollsQuestion,
    staleTime: Infinity
  });

  const { mutate: vote } = useMutation({
    mutationKey: ['vote'],
    mutationFn: pollsQueries.voteQuestion,
    onSuccess: (result, variables) => {
      setUuid(result.uuid);
      setAnswer({ ...answer, uuid: result.uuid, choice: variables.choice });
      navigate('/comment', { state: { prevUrl: '/vote' } });
    },
    onError(error) {
      if (axios.isAxiosError(error)) {
        const serverResponse = error.response;
        if (serverResponse && serverResponse.status === 429) {
          navigate('/too-many-requests', { state: { prevUrl: '/vote' } });
        }
      }
    },
  });

  const question: Question | undefined = questions?.[0];

  return (
    <Layout>
      <div className="container">
        <div className="question">• Wat denk jij?</div>
        <div className="subject">💭 {question?.question}</div>
        {
          question?.choices?.map((choice: Choice) =>
            <Button
              onClick={() => {
                let answer: Answer = { choice: Number(choice.id) };
                vote(answer);
              }}
              key={choice.id}
            >{choice.choice}</Button>)
        }
      </div>
    </Layout>
  );
};

export default Vote;