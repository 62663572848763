import React from 'react';
import './button.scss';
import { Button } from '@chakra-ui/react';
import iconBack from '../../../src/assets/images/icon-back.svg';
import { useNavigate } from 'react-router-dom';

const BackButton: React.FC = () => {
    const navigate = useNavigate();
    
    const onClick = () => {
        navigate(-1);
    };

    return (
        <Button
            alignItems={"center"}
            justifyContent={"flex-start"}
            width={"52px"}
            height={"52px"}
            borderRadius={"50%"}
            border={"solid 0.5px brand.basicGrey25p"}
            background={"brand.basicWhite"}
            _hover={{
                background: "brand.basicGrey25p"
            }}
            _active={{
                background: "brand.basicGrey25p"
            }}
            onClick={onClick}
        >
            <img src={iconBack} alt="back" />
        </Button>
    );
};

export default BackButton;
