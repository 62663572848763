import axios from 'axios';

export const API_URL = process.env.REACT_APP_API_URL;

const instance = axios.create({
    baseURL: `${API_URL}/api`,
    responseType: "json",
    headers: {
        "Content-Type": "application/json; charset=UTF-8"
    }
});

export { instance };